import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./styles.css";

const CategoryList = ({ categories, onClick }) => {
  const list = categories.map((category, index) => (
    <Link
      className="category-item__link"
      key={index}
      to={`/portfolio/${category}`}
    >
      <li
        key={index}
        className="category-item__btn"
        onClick={() => onClick(category)}
      >
        {category}
      </li>
    </Link>
  ));

  return <ul className="categories-list">{list}</ul>;
};

export default CategoryList;
