import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./styles.css";
import DoubleHeaderDescription from "../DoubleHeaderDescription/index";
import PortfolioCardList from "../PortfolioCardList";
import { portfolioCardData } from "../../portfolioData";
import CategoryList from "../CategoryList";
import SocialHeaderDescription from "../SocialHeaderDescription/index";

const extractTags = (data) => {
  const result = ["All"];

  data.forEach((item) => item.tags.forEach((tag) => result.push(tag)));

  return result;
};

const filterData = (data, value) => {
  if (value === "All") return data;
  return data.filter((item) => item.tags.includes(value));
};

const Portfolio = (props) => {
  const { category } = useParams();
  const [cardData, setCardData] = useState(portfolioCardData);
  const tags = extractTags(portfolioCardData);
  const categories = [...new Set(tags)];

  useEffect(() => {
    if (category) {
      setCardData(filterData(portfolioCardData, category));
    }
    document.body.style.overflow = "auto";
  }, [category]);

  const handleClick = (value) => {
    setCardData(filterData(portfolioCardData, value));
  };

  return (
    <section className="portfolio-page">
      <SocialHeaderDescription
        pageTitleDH={"Portfolio"}
        spanTitleDH={"Featured here are some of the work we've done"}
      />
      <h2 className="header">
        The latest from our digital <span>experts</span>
      </h2>
      <div className="outer-container">
        <div className="categories">
          <CategoryList categories={categories} onClick={handleClick} />
        </div>
        <div className="portfolio-container">
          <PortfolioCardList portfolioData={cardData} />
        </div>
      </div>
    </section>
  );
};
export default Portfolio;
