/* eslint-disable import/no-duplicates */

import React, { useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Header from "./components/Header";
import DoubleHeader from "./components/DoubleHeader";
import TopHeader from "./components/TopHeader";
import EntryBanner from "./components/EntryBanner";
import Services from "./components/ServicesOverview";
import ServiceOfferedBPlanning from "./components/ServiceOffered/servicesOffered";
import FAQ from "./components/FAQ";

import Login from "./components/Login";
import Principles from "./components/Principles";
import Contact from "./components/Contact";
import Chat from "./components/Chat";
import SideMenu from "./components/SideMenu";

import Portfolio from "./components/Portfolio";
import Products from "./components/Principles";
import Team from "./components/Principles";

import CookiePolicy from "./components/CookiePolicy";
import PrivacyPolicy from "./components/PrivacyPolicy";
import TermsOfUse from "./components/TermsOfUse";

import InitiateProject from "./components/InitiateProject";

import "./App.css";

import ModalBox from "./components/ModalBox";

export default function BasicExample() {
  const [modalShow, setModalShow] = useState(false);
  const [caseTitle, setCaseTitle] = useState("Example Project");
  const [caseContent, setCaseContent] = useState("We worked on this");

  const openModal = (title, content) => {
    setCaseTitle(title);
    setCaseContent(content);
    setModalShow(true);
  };
  // revert branch

  return (
    <Router>
      <React.Fragment>
        {/* <Header openModal={openModal} />
        <SideMenu /> */}
        <TopHeader openModal={openModal} />
        <DoubleHeader />

        <Switch>
          <Route path="/services-offered/:service">
            <ServiceOfferedBPlanning />
          </Route>
          <Route path="/services">
            <Services />
          </Route>
          <Route path="/FAQ/">
            <FAQ />
          </Route>
          <Route path="/principles">
            <Principles />
          </Route>
          <Route path="/project">
            <InitiateProject />
          </Route>
          <Route path="/portfolio/:category">
            <Portfolio openModal={openModal} />
          </Route>
          <Route path="/portfolio">
            <Portfolio openModal={openModal} />
          </Route>
          <Route path="/products">
            <Products />
          </Route>
          <Route path="/team">
            <Team />
          </Route>
          <Route path="/contact/">
            <Contact />
          </Route>
          <Route path="/chat/">
            <Chat />
          </Route>
          <Route path="/clients/">
            <Login />
          </Route>

          <Route path="/cookiepolicy/">
            <CookiePolicy />
          </Route>

          <Route path="/privacypolicy/">
            <PrivacyPolicy />
          </Route>

          <Route path="/termsofuse/">
            <TermsOfUse />
          </Route>

          <Route path="/">
            <EntryBanner />
          </Route>
        </Switch>

        <ModalBox
          show={modalShow}
          onHide={() => setModalShow(false)}
          title={caseTitle}
          content={caseContent}
        />
      </React.Fragment>
    </Router>
  );
}
