import React, { useState } from "react";
import ContactModal from "../ContactModal";
import { postRequest } from "../../services/api";
import "./styles.css";

const MessageForm = (props) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [sent, setSent] = useState(false);
  const [openResponse, setOpenResponse] = useState(false);

  const closeRepsonseModal = () => {
    setOpenResponse(false);
  };

  const openResponseModal = () => {
    setOpenResponse(true);
  };

  const resetForm = () => {
    setName("");
    setEmail("");
    setPhone("");
    setMessage("");
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const data = {
      name,
      email,
      phone,
      message,
    };

    console.log(data);

    if (!/^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      setSent(false);
    }
    if (phone.length < 10) {
      setSent(false);
    }
    // Send form information
    else {
      const submitted = postRequest("contact/send", data);
      setSent(true);
      resetForm();
      console.log("SUBMITTED ", submitted);
    }
  };

  return (
    <div className="inner">
      <div>
        <h4 className="heading-title">
          Complete the form below and we'll get back to you
        </h4>
        <form className="message-form" onSubmit={handleSubmit}>
          <div className="form-inputs">
            <input
              type="text"
              placeholder="Your Name"
              name="firstname"
              value={name}
              className="form-input"
              onChange={(event) => setName(event.target.value)}
            />
            <br />
            <input
              type="email"
              placeholder="Your Email"
              name="email"
              value={email}
              className="form-input"
              onChange={(event) => setEmail(event.target.value)}
            />
            <br />
            <input
              type="text"
              placeholder="Your Phone Number (optional)"
              name="phoneno"
              value={phone}
              className="form-input"
              onChange={(event) => setPhone(event.target.value)}
            />
            <br />
            <textarea
              placeholder="Your Message"
              name="message"
              value={message}
              className="form-input"
              onChange={(event) => setMessage(event.target.value)}
            ></textarea>
          </div>
          <button className="contact-button" onClick={openResponseModal}>
            Send Message
          </button>
          {openResponse && (
            <ContactModal
              username={name}
              closeResponseModal={closeRepsonseModal}
              className="responsePage"
            />
          )}
        </form>
      </div>
    </div>
  );
};

export default MessageForm;
