import React from "react";
import "./styles.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link, useLocation } from "react-router-dom";
import { AiOutlineClose } from "react-icons/ai";
import { MenuFooter } from "../Footer";

const SlideOutMenuDH = ({ openSideMenu, closeSideMenu }) => {
  AOS.init();
  return (
    <div
      className="slideOutDH-container"
      data-aos="fade-left"
      data-aos-offset="500"
      data-aos-duration="500"
      data-aos-delay="50"
    >
      <div className="close-slideout">
        <AiOutlineClose onClick={closeSideMenu} />
      </div>
      <Link
        className="slideout-links slideout-link1"
        to="/"
        onClick={closeSideMenu}
      >
        Home
      </Link>
      <Link className="slideout-links" to="/services" onClick={closeSideMenu}>
        Our services
      </Link>
      <Link className="slideout-links" to="/portfolio" onClick={closeSideMenu}>
        Our work
      </Link>
      <Link className="slideout-links" to="/pencraft" onClick={closeSideMenu}>
        Our thoughts
      </Link>
      <Link className="slideout-links" to="/contact" onClick={closeSideMenu}>
        Contact
      </Link>
      <MenuFooter />
    </div>
  );
};

export default SlideOutMenuDH;
