import React, { useEffect, useRef, useState } from "react";
import { FaArrowRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import ClientCaseStudy from "../ClientCaseStudy";
import "./styles.css";

const PortfolioCard = ({ data }) => {
  const [openCaseStudy, setOpenCaseStudy] = useState(false);

  const ref = useRef();
  useEffect(() => {
    const checkIfClickedModal = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (openCaseStudy && ref.current && !ref.current.contains(e.target)) {
        setOpenCaseStudy(false);
      }
      if (
        openCaseStudy &&
        ref.current &&
        !ref.current.contains(e.keyCode === 27)
      ) {
        setOpenCaseStudy(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedModal);
    document.addEventListener("keydown", checkIfClickedModal);

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedModal);
      document.removeEventListener("keydown", checkIfClickedModal);
    };
  }, [openCaseStudy]);

  const openModal = () => {
    setOpenCaseStudy(true);
  };

  const closeModal = () => {
    setOpenCaseStudy(false);
  };

  const modal = () => {
    if (data.style !== "question") {
      return (
        <React.Fragment>
          <Link onClick={openModal}>
            <FaArrowRight className="arrow" />
          </Link>
          {openCaseStudy && (
            <div ref={ref} className="caseStudy-container">
              <ClientCaseStudy
                companyName={data.companyName}
                description={data.description}
                closeModal={closeModal}
                className="caseStudy"
              />
            </div>
          )}
        </React.Fragment>
      );
    }
    return null;
  };

  const LinkArrow = () => {
    if (data.style === "question") {
      return (
        <Link exact to={data.link}>
          <FaArrowRight className="arrow" />
        </Link>
      );
    }
    return null;
  };

  return (
    <div className="container">
      <div className={`card ${data.style}`}>
        <div className="face face1" onClick={openModal}>
          <div className="content">
            <p className="description">{data.description}</p>
            <div className="tags">
              {data.tags
                ? data.tags.map((tag) => {
                    return (
                      <div key={tag}>
                        <div className="tag">{tag}</div>
                      </div>
                    );
                  })
                : null}
            </div>
            {LinkArrow()}
            {modal()}
          </div>
        </div>
        {data.style !== "question"
          ? openCaseStudy && (
              <div ref={ref} className="caseStudy-container">
                <ClientCaseStudy
                  companyName={data.companyName}
                  description={data.description}
                  closeModal={closeModal}
                  className="caseStudy"
                />
              </div>
            )
          : null}
        <div className={`face face2 ${data.style}`}>
          <img src={data.logo} alt={data.companyName} className="cardImage" />
        </div>
      </div>
    </div>
  );
};

export default PortfolioCard;
