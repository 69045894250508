import React, { useEffect } from "react";

// import "./styles.css";

const TermsOfUse = (props) => {
  useEffect(() => {
    document.body.style.overflow = "auto";
  }, []);
  return (
    <section id="our-work" className="wrapper style1  screen-size">
      <header className="heading">
        <h2 className="title">Terms of Use</h2>
        <span className="subtitle">
          Rules around using our site and services
        </span>
      </header>
      <div className="inner">
        <div className="pane-wrapper">
          <div className="left-pane">
            <section
              dir="LTR"
              className="kb-article kb-article-variant gradient"
              data-countries=",MC,FR,"
            >
              <div className="page-block">
                <div className="c-wrapper">
                  <div>
                    <p>
                      Spark Strand provides a personalized subscription service
                      that allows our members to access movies and TV shows
                      ("Spark Strand content") streamed over the Internet to
                      certain Internet-connected TVs, computers and other
                      devices ("Spark Strand ready devices").&nbsp;
                    </p>
                    <p>
                      These Terms of Use govern your use of our service. As used
                      in these Terms of Use, "Spark Strand service", "our
                      service" or "the service" means the personalized service
                      provided by Spark Strand for discovering and watching
                      Spark Strand content, including all features and
                      functionalities, recommendations and reviews, the website,
                      and user interfaces, as well as all content and software
                      associated with our service.
                    </p>
                    <ol>
                      <li>
                        <p>
                          <strong>Membership</strong>
                          1.1. Your Spark Strand membership will continue until
                          terminated. To use the Spark Strand service you must
                          have Internet access and a Spark Strand ready device,
                          and provide us with one or more Payment Methods.
                          "Payment Method" means a current, valid, accepted
                          method of payment, as may be updated from time to
                          time, and which may include payment through your
                          account with a third party. Unless you cancel your
                          membership before your billing date, you authorize us
                          to charge the membership fee for the next billing
                          cycle to your Payment Method (see "Cancellation"
                          below). 1.2. We may offer a number of membership
                          plans, including memberships offered by third parties
                          in conjunction with the provision of their own
                          products and services. Some membership plans may have
                          differing conditions and limitations, which will be
                          disclosed at your sign-up or in other communications
                          made available to you. You can find specific details
                          regarding your Spark Strand membership by visiting our
                          website and clicking on the "Account" link available
                          at the top of the pages under your profile name.
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>Promotional Offers.</strong> We may from time
                          to time offer special promotional offers, plans or
                          memberships (“Offers”). Offer eligibility is
                          determined by Spark Strand at its sole discretion and
                          we reserve the right to revoke an Offer and put your
                          account on hold in the event that we determine you are
                          not eligible. Members of households with an existing
                          or recent Spark Strand membership may not be eligible
                          for certain introductory Offers. We may use
                          information such as device ID, method of payment or an
                          account email address used with an existing or recent
                          Spark Strand membership to determine Offer
                          eligibility. The eligibility requirements and other
                          limitations and conditions will be disclosed when you
                          sign-up for the Offer or in other communications made
                          available to you.
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>Billing and Cancellation</strong>3.1.{" "}
                          <span className="underline">Billing Cycle.</span> The
                          membership fee for the Spark Strand service will be
                          charged to your Payment Method on the specific payment
                          date indicated on the&nbsp; "Account" page. The length
                          of your billing cycle will depend on the type of
                          subscription that you choose when you signed up for
                          the service. In some cases your payment date may
                          change, for example if your Payment Method has not
                          successfully settled, when you change your
                          subscription plan or if your paid membership began on
                          a day not contained in a given month. Visit our
                          website and click on the "Billing details" link on the
                          "Account" page to see your next payment date. If you
                          signed up for Spark Strand using your account with a
                          third party as a Payment Method, you can find the
                          billing information about your Spark Strand membership
                          by visiting your account with the applicable third
                          party. 3.2.{" "}
                          <span className="underline">Payment Methods.</span> To
                          use the Spark Strand service you must provide one or
                          more Payment Methods. You authorize us to charge any
                          Payment Method associated to your account in case your
                          primary Payment Method is declined or no longer
                          available to us for payment of your subscription fee.
                          You remain responsible for any uncollected amounts. If
                          a payment is not successfully settled, due to
                          expiration, insufficient funds, or otherwise, and you
                          do not cancel your account, we may suspend your access
                          to the service until we have successfully charged a
                          valid Payment Method. For some Payment Methods, the
                          issuer may charge you certain fees, such as foreign
                          transaction fees or other fees relating to the
                          processing of your Payment Method. Local tax charges
                          may vary depending on the Payment Method used. Check
                          with your Payment Method service provider for details.
                          3.3.{" "}
                          <span className="underline">
                            Updating your Payment Methods.
                          </span>{" "}
                          You can update your Payment Methods by going to the
                          "Account" page. We may also update your Payment
                          Methods using information provided by the payment
                          service providers. Following any update, you authorize
                          us to continue to charge the applicable Payment
                          Method(s). 3.4.{" "}
                          <span className="underline">Cancellation.</span> You
                          can cancel your Spark Strand membership at any time,
                          and you will continue to have access to the Spark
                          Strand service through the end of your billing period.
                          Payments are non-refundable and we do not provide
                          refunds or credits for any partial membership periods
                          or unwatched Spark Strand content. To cancel, go to
                          the "Account" page and follow the instructions for
                          cancellation. If you cancel your membership, your
                          account will automatically close at the end of your
                          current billing period. To see when your account will
                          close, click "Billing details" on the "Account" page.
                          If you signed up for Spark Strand using your account
                          with a third party as a Payment Method and wish to
                          cancel your Spark Strand membership, you may need to
                          do so through such third party, for example by
                          visiting your account with the applicable third party
                          and turning off auto-renew, or unsubscribing from the
                          Spark Strand service through that third party. 3.5.{" "}
                          <span className="underline">
                            Changes to the Price and Subscription Plans.
                          </span>{" "}
                          We may change our subscription plans and the price of
                          our service from time to time; however, any price
                          changes or changes to your subscription plans will
                          apply no earlier than 30 days following notice to you.
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>Spark Strand Service</strong>4.1. You must be
                          at least 18 years of age to become a member of the
                          Spark Strand service. Minors may only use the service
                          under the supervision of an adult. 4.2. The Spark
                          Strand service and any content viewed through the
                          service are for your personal and non-commercial use
                          only and may not be shared with individuals beyond
                          your household. During your Spark Strand membership we
                          grant you a limited, non-exclusive, non-transferable
                          right to access the Spark Strand service and view
                          Spark Strand content. Except for the foregoing, no
                          right, title or interest shall be transferred to you.
                          You agree not to use the service for public
                          performances. 4.3. You may view the Spark Strand
                          content primarily within the country in which you have
                          established your account and only in geographic
                          locations where we offer our service and have licensed
                          such content. The content that may be available to
                          watch will vary by geographic location and will change
                          from time to time. The number of devices on which you
                          may simultaneously watch depends on your chosen
                          subscription plan and is specified on the "Account"
                          page. 4.4. The Spark Strand service, including the
                          content library, is regularly updated. In addition, we
                          continually test various aspects of our service,
                          including our website, user interfaces, promotional
                          features and availability of Spark Strand content. You
                          can turn off tests participation at any time by
                          visiting the "Account" page and changing the "Test
                          participation" settings. 4.5. Some Spark Strand
                          content is available for temporary download and
                          offline viewing on certain supported devices ("Offline
                          Titles"). Limitations apply, including restrictions on
                          the number of Offline Titles per account, the maximum
                          number of devices that can contain Offline Titles, the
                          time period within which you will need to begin
                          viewing Offline Titles and how long the Offline Titles
                          will remain accessible. Some Offline Titles may not be
                          playable in certain countries and if you go online in
                          a country where you would not be able to stream that
                          Offline Title, the Offline Title will not be playable
                          while you are in that country. 4.6. You agree not to
                          archive, reproduce, distribute, modify, display,
                          perform, publish, license, create derivative works
                          from, offer for sale, or use (except as explicitly
                          authorized in these Terms of Use) content and
                          information contained on or obtained from or through
                          the Spark Strand service. You also agree not to:
                          circumvent, remove, alter, deactivate, degrade or
                          thwart any of the content protections in the Spark
                          Strand service; use any robot, spider, scraper or
                          other automated means to access the Spark Strand
                          service; decompile, reverse engineer or disassemble
                          any software or other products or processes accessible
                          through the Spark Strand service; insert any code or
                          product or manipulate the content of the Spark Strand
                          service in any way; or use any data mining, data
                          gathering or extraction method. In addition, you agree
                          not to upload, post, e-mail or otherwise send or
                          transmit any material designed to interrupt, destroy
                          or limit the functionality of any computer software or
                          hardware or telecommunications equipment associated
                          with the Spark Strand service, including any software
                          viruses or any other computer code, files or programs.
                          We may terminate or restrict your use of our service
                          if you violate these Terms of Use or are engaged in
                          illegal or fraudulent use of the service. 4.7. The
                          quality of the display of the Spark Strand content may
                          vary from device to device, and may be affected by a
                          variety of factors, such as your location, the
                          bandwidth available through and/or speed of your
                          Internet connection. HD, Ultra HD and HDR availability
                          is subject to your Internet service and device
                          capabilities. Not all content is available in all
                          formats, such as HD, Ultra HD and HDR, and not all
                          subscription plans allow you to receive content in all
                          formats. Default playback settings on cellular
                          networks exclude HD, Ultra HD and HDR content. The
                          minimum connection speed for SD quality is 1.0 Mbps;
                          however, we recommend a faster connection for improved
                          video quality. A download speed of at least 3.0 Mbps
                          per stream is recommended to receive HD content
                          (defined as a resolution of 720p or higher). A
                          download speed of at least 15.05 Mbps per stream is
                          recommended to receive Ultra HD (defined as a
                          resolution of 4K or higher). You are responsible for
                          all Internet access charges. Please check with your
                          Internet provider for information on possible Internet
                          data usage charges. The time it takes to begin
                          watching Spark Strand content will vary based on a
                          number of factors, including your location, available
                          bandwidth at the time, the content you have selected
                          and the configuration of your Spark Strand ready
                          device. 4.8. Spark Strand software is developed by, or
                          for, Spark Strand and may solely be used for
                          authorized streaming and viewing of Spark Strand
                          content through Spark Strand ready devices. This
                          software may vary by device and medium, and
                          functionalities and features may also differ between
                          devices. You acknowledge that the use of the service
                          may require third party software that is subject to
                          third party licenses. You agree that you may
                          automatically receive updated versions of the Spark
                          Strand and related third-party software.
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>Passwords and Account Access.</strong> The
                          member who created the Spark Strand account and whose
                          Payment Method is charged (the "Account Owner") is
                          responsible for any activity that occurs through the
                          Spark Strand account. To maintain control over the
                          account and to prevent anyone from accessing the
                          account (which would include information on viewing
                          history for the account), the Account Owner should
                          maintain control over the Spark Strand ready devices
                          that are used to access the service and not reveal the
                          password or details of the Payment Method associated
                          with the account to anyone. You are responsible for
                          updating and maintaining the accuracy of the
                          information you provide to us relating to your
                          account. We can terminate your account or place your
                          account on hold in order to protect you, Spark Strand
                          or our partners from identity theft or other
                          fraudulent activity.
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>Miscellaneous</strong>
                          6.1. <span className="underline">
                            Governing Law.
                          </span>{" "}
                          These Terms of Use shall be governed by and construed
                          in accordance with the laws of France. 6.2.{" "}
                          <span className="underline">
                            Unsolicited Materials.
                          </span>{" "}
                          Spark Strand does not accept unsolicited materials or
                          ideas for Spark Strand content, and is not responsible
                          for the similarity of any of its content or
                          programming in any media to materials or ideas
                          transmitted to Spark Strand. 6.3.{" "}
                          <span className="underline">Customer Support.</span>{" "}
                          To find more information about our service and its
                          features or if you need assistance with your account,
                          please visit the Spark Strand Help Center on our
                          website. In certain instances, Customer Service may
                          best be able to assist you by using a remote access
                          support tool through which we have full access to your
                          computer. If you do not want us to have this access,
                          you should not consent to support through the remote
                          access tool, and we will assist you through other
                          means. 6.4.{" "}
                          <span className="underline">Survival.</span> If any
                          provision or provisions of these Terms of Use shall be
                          held to be invalid, illegal, or unenforceable, the
                          validity, legality and enforceability of the remaining
                          provisions shall remain in full force and effect. 6.5.{" "}
                          <span className="underline">
                            Changes to Terms of Use.
                          </span>{" "}
                          Spark Strand may, from time to time, change these
                          Terms of Use. We will notify you at least 30 days
                          before such changes apply to you. 6.6.{" "}
                          <span className="underline">
                            Electronic Communications.
                          </span>{" "}
                          We will send you information relating to your account
                          (e.g. payment authorizations, invoices, changes in
                          password or Payment Method, confirmation messages,
                          notices) in electronic form only, for example via
                          emails to your email address provided during
                          registration.
                        </p>
                      </li>
                    </ol>
                    <p></p>
                    <p></p>
                  </div>
                </div>
              </div>

              <p>
                <strong>Last Updated:</strong> January 1, 2021
              </p>
            </section>
          </div>

          <div className="right-pane">
            <div className="wrapper page-block"></div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TermsOfUse;
