import React from "react";
import "./styles.css";

const DoubleHeaderDescription = ({ pageTitle, spanTitle }) => {
  return (
    <div className="description-container">
      <heading className="heading heading-wrapper">
        <h2 className="title">{pageTitle}</h2>
        <span className="subtitle">{spanTitle}</span>
      </heading>
    </div>
  );
};

export default DoubleHeaderDescription;
