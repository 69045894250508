import React, { useState, useEffect } from "react";
import "./Login.css";
import SocialHeaderDescription from "./SocialHeaderDescription";

function Login() {
  useEffect(() => {
    document.body.style.overflow = "hidden";
  }, []);
  const [sent, setSent] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    setSent(true);
  };

  if (sent)
    return (
      <header>
        <h2 className="login-message">Undergoing maintenance</h2>
      </header>
    );

  return (
    <section className="login-bg">
      <SocialHeaderDescription
        pageTitleDH={"Login page"}
        spanTitleDH={"Sign in!"}
      />
      <div>
        <div id="form_container">
          <form
            id="contactss"
            className=""
            method="post"
            action="<?=base_url('login')?>"
          >
            <div className="form_description">
              <h2>Login</h2>
              <p>Enter your details below and then click login </p>
            </div>
            <ul>
              <li className="fields">
                <label>Username/Email</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  maxLength="255"
                  placeholder="Your Email"
                />
              </li>
              <li className="fields">
                <label>Password</label>
                <input
                  type="password"
                  id="password"
                  name="password"
                  maxLength="255"
                  placeholder="Your password"
                />
              </li>
              <li className="fields">
                <input
                  onClick={handleSubmit}
                  type="submit"
                  className="contact-button"
                  value="Login"
                />
              </li>
            </ul>
          </form>
        </div>
      </div>
    </section>
  );
}

export default Login;
