export const portfolioCardData = [
  {
    companyName: "My French Community",
    description:
      "A social networking site for those of French origin, or with an interest in French culture - complete with a members' directory, news feeds, status updates, and private messaging. directory, news feeds, status updates, and private messaging.directory, news feeds, status updates, and private messaging.",
    logo: "images/client-images/MyFrenchCommunityBlack.png",
    tags: ["Website"],
    link: "",
  },
  {
    companyName: "AreaDoc",
    description:
      "Monitor Healthcare desired a contemporary website to promote the features of their new mobile app which helps you locate a doctor near you.",
    logo: "images/client-images/AreaDoc.png",
    tags: ["Website", "Graphics"],
    link: "",
  },
  {
    companyName: "Obolynx",
    description:
      "An educational community website, with revision content and messaging forums for UK Students.",
    logo: "images/client-images/Obolynx.png",
    tags: ["Website", "Graphics"],
    link: "",
  },
];

export default portfolioCardData;
