/* eslint-disable no-use-before-define */
// please do not remove the line above
import React, { useState, useEffect } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import "./styles.css";
import DoubleHeaderDescription from "../DoubleHeaderDescription";

import options from "../../data";

const ProjectForm = (props) => {
  useEffect(() => {
    document.body.style.overflow = "hidden";
  }, []);

  const [projectName, setProjectName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [sent, setSent] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();

    const data = { projectName, email, phone, message };

    // Send form information
    fetch("/contact/send", {
      method: "post",
      body: JSON.stringify(data),
    }).then(() => setSent(true) && resetForm());
  };

  const resetForm = () => {
    setProjectName("");
    setEmail("");
    setPhone("");
    setMessage("");
  };

  if (sent)
    return (
      <header className="align-center message-sent">
        <h2>Message sent</h2>
      </header>
    );

  return (
    // <div className="inner initiate-project">
    <div className="contact-form">
      <div>
        <h4 className="page-title">Enter the details about your project</h4>
        {/* <h5>Recieve an instant quote, with no obligations to continue</h5> */}
      </div>
      <div>
        <form className="initiate-project-form" onSubmit={handleSubmit}>
          <p>
            <input
              className="input-field"
              type="text"
              placeholder="Project Name"
              name="projectName"
              value={projectName}
              onChange={(event) => setProjectName(event.target.value)}
            />
            <br />
            <Typeahead
              className="input-field"
              id="services-required"
              labelKey="name"
              multiple
              options={options}
              placeholder="Specify the services required"
            />
            <br />
            <textarea
              className="input-field"
              placeholder="Project description"
              name="message"
              value={message}
              onChange={(event) => setMessage(event.target.value)}
            ></textarea>
            <br />
            <input
              className="input-field"
              type="date"
              placeholder="Desired completion date"
              name="phoneno"
              value={phone}
              onChange={(event) => setPhone(event.target.value)}
              id="completionDate"
              min={new Date().toISOString().split("T")[0] || "2021-04-03"}
            />
          </p>
          <button className="button-big">Send</button>
        </form>
      </div>
    </div>
  );
};

const InitiateProject = (props) => (
  <React.Fragment>
    <DoubleHeaderDescription />
    <section className="project-start">
      <div className="contact-form">
        <ProjectForm />
      </div>
    </section>
  </React.Fragment>
);

export default InitiateProject;
