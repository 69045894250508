import React from "react";
import "./styles.css";

const ContactModal = ({ closeResponseModal, username }) => {
  return (
    <div className="contactModalContainer">
      <div className="contactModal">
        <div className="success success-animation icon-top">
          <i className="fa fa-check"></i>
        </div>
        <div className="success border-bottom"></div>
        <div className="contactModal-content">
          <p className="contactModal-title">Thank you {username}</p>
          <p className="contactModal-message">
            Your inquiry has been sent. We'll be in touch with you soon.
          </p>
          <button
            className="contactModal-closeBtn"
            onClick={closeResponseModal}
          >
            CLOSE
          </button>
        </div>
      </div>
    </div>
  );
};

export default ContactModal;
