import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { FaCookie } from "react-icons/fa";
import { FcPrivacy } from "react-icons/fc";
import { MdAssignment } from "react-icons/md";
import ActionButton from "../ActionButton";
import SocialHeaderDescription from "../SocialHeaderDescription";
import Footer from "../Footer";

import "./styles.css";

const EntryBanner = (props) => {
  useEffect(() => {
    document.body.style.overflow = "hidden";
  }, []);
  return (
    <section id="banner" className="banner-inner">
      <SocialHeaderDescription pageTitleDH={"Home"} spanTitleDH={"Home page"} />
      <div className="inner">
        <header>
          <p>
            <img
              src="images/Logo-white.png"
              width="250"
              height="100"
              alt="Spark Strand"
            />
          </p>
          <h1>Your digital experts!</h1>
          <p>
            At Spark Strand, we build cool digital stuff. We are a creative
            digital and marketing agency. We use our design, web development,
            and marketing skills to help bring your ideas to life and get you
            noticed.
          </p>
        </header>
        <Link
          to="/services"
          className="button big scrolly button-stylings white-button"
        >
          More about what we do
        </Link>
        <Link
          to="/project"
          className="button big scrolly start-red button-stylings red-button"
        >
          Start your project
        </Link>
      </div>
      <div className="homepage-actionbutton">
        <ActionButton
          firstLink="cookiepolicy/"
          firstAction={<FaCookie />}
          secondLink="privacypolicy/"
          secondAction={<FcPrivacy />}
          thirdLink="termsofuse/"
          thirdAction={<MdAssignment />}
        />
      </div>
      <div className="entry-footer">
        <Footer />
      </div>
    </section>
  );
};

export default EntryBanner;
