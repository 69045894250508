import React from "react";

const Principles = (props) => (
  <section id="principles" className="wrapper style2">
    <div className="inner">
      <div className="flex flex-2">
        <h2>How we do it (our principles)</h2>
        <div className="col col2">
          <p>
            At Spark Strand, it's all about you - we will do our best to do what
            is good for you. Your success is our success. We value long-term
            relationships and think of our clients' products and services as our
            own. We want to deliver an excellent service and make sure that our
            clients genuinely enjoy working with us as much as we enjoy working
            with them. As part of this we also strive to provide services at
            prices that are affordable for you and we work according to your
            budget.
          </p>
          <p>
            We're all about creativity backed by extensive research and
            planning. We take a transparent and collaborative approach towards
            our work and our clients. Bringing together multi-talented
            individuals.
          </p>
          <a href="#" className="button">
            Learn More
          </a>
          <a href="#our-work" className="button big scrolly">
            See some of our work
          </a>
          <a href="#worktogether" className="button big scrolly">
            Start your own project
          </a>
        </div>
        <div className="col col1 first">
          <div className="image squircle fit">
            <a href="generic.html" className="link">
              <img src="images/pic02.jpg" alt="" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default Principles;
