import React, { useState, useEffect } from "react";
import MessageForm from "../MessageForm";
import Footer from "../Footer";
import SocialHeaderDescription from "../SocialHeaderDescription";

import "./styles.css";

const Contact = (props) => {
  useEffect(() => {
    document.body.style.overflow = "auto";
  }, []);
  return (
    <section className="contact-section">
      <SocialHeaderDescription
        pageTitleDH={"Contact Us"}
        spanTitleDH={"We'll get back to you"}
      />
      <div>
        <MessageForm />
      </div>
      <section className="bottom-half">
        <section className="top-half">
          <div>
            <div className="bottom-down-below">
              <div>
                <h4 className="footer-headings">Contact Us</h4>
                <p className="footer-text">
                  hello@sparkstrand.com
                  <br />
                  <br />
                  +44 0330 133 4777
                </p>
              </div>
              <div>
                <h4 className="footer-headings">Address</h4>
                <p className="footer-text">
                  Office One,
                  <br />
                  1 Coldbath Square,
                  <br />
                  Farringdon, London,
                  <br />
                  England, EC1R 5HL
                </p>

                <p className="footer-text anomaly-footer">
                  5 Rue de Tanger
                  <br />
                  Paris, 75019
                  <br />
                  France
                </p>
              </div>
            </div>
          </div>
        </section>
        <div className="socialMedia-container">
          <header>
            <h4 className="footer-headings anomaly-heading">Social Media</h4>
          </header>
          <div>
            <ul className="socialBeam">
              <li
                className="facebook"
                data-toggle="tooltip"
                data-placement="bottom"
                title="Facebook"
              >
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.facebook.com/sparkstrand/"
                >
                  <i className="fab fa-facebook-f fb"></i>
                </a>
              </li>
              <li
                className="facebook"
                data-toggle="tooltip"
                data-placement="bottom"
                title="Facebook"
              >
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.instagram.com/spark_strand/"
                >
                  <i className="fab fa-instagram insta"></i>
                </a>
              </li>
              <li
                className="facebook"
                data-toggle="tooltip"
                data-placement="bottom"
                title="Twitter"
              >
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://twitter.com/sparkstrand"
                >
                  <i className="fab fa-twitter twt"></i>
                </a>
              </li>
              <li
                className="linkedin"
                data-toggle="tooltip"
                data-placement="bottom"
                title="Linkedin"
              >
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.linkedin.com/company/sparkstrand"
                >
                  <i className="fab fa-linkedin linkedin"></i>
                </a>
              </li>
              <li
                className="linkedin"
                data-toggle="tooltip"
                data-placement="bottom"
                title="Whatsapp"
              >
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://api.whatsapp.com/send?phone=447985538094"
                >
                  <i className="fab fa-whatsapp whtsapp"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <div className="contact-footer">
        <Footer />
      </div>
    </section>
  );
};

export default Contact;
