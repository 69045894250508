/* eslint-disable no-console */
/* eslint-disable */
/* eslint-disable prettier/prettier */

const apiUrl = process.env.API_URL || process.env.REACT_APP_API_URL || "/api";

const postRequest = async (url, data) => {
  url = apiUrl + url;

  const result = await fetch(url, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  })
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      console.log(error);
      return error.message;
    });
  return result;
};

export { postRequest };
