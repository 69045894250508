/* eslint-disable consistent-return */
/* eslint-disable eqeqeq */
import React from "react";
import { useParams, Link } from "react-router-dom";
import { IoIosArrowDown } from "react-icons/io";
import { GiChessKnight } from "react-icons/gi";
import { FaPencilAlt } from "react-icons/fa";
import { BiMobileAlt, BiLike } from "react-icons/bi";
import { MdOutlineDeveloperMode } from "react-icons/md";
import { RiAdvertisementLine } from "react-icons/ri";
import SubServiceCard from "../SubServiceCard/index";
import MainService from "../MainService";
import DoubleHeaderDescription from "../DoubleHeaderDescription";
import SocialHeaderDescription from "../SocialHeaderDescription";

import SubServiceCardList from "../../SubServiceCardList/SubServiceCardList";

import "./serviceOffered.css";
import "./serviceOffered_BImpementation.css";

const Services = () => {
  const { service } = useParams();
  if (service == "brand-planning") {
    return (
      <section className="services-offered">
        <SocialHeaderDescription
          pageTitleDH={"Brand Planning"}
          spanTitleDH={"Services we offer"}
        />
        <MainService
          title={
            <div>
              Brand Planning & <span className="last-word">Design</span>
            </div>
          }
          description="Design and understand your brand. Lay the groundwork for your brand to thrive. Your brand is the way your customer perceives you, do not leave it to chance."
        />

        <div className="card-wrapper">
          <SubServiceCardList serviceTitle={"Brand Planning"} />
        </div>

        <div className="down-arrow">
          <Link exact to="/services-offered/brand-implementation">
            <h3>
              <IoIosArrowDown size={40} />
            </h3>
          </Link>
        </div>
      </section>
    );
  }
  if (service == "brand-implementation") {
    return (
      <section className="services-offered">
        {/* <DoubleHeaderDescription
        pageTitle={"Brand Implementation"}
        spanTitle={"Services we offer"}
      /> */}
        <SocialHeaderDescription
          pageTitleDH={"Brand Implementation"}
          spanTitleDH={"Services we offer"}
        />
        <MainService
          title={
            <div>
              Brand <span className="last-word">Implementation</span>
            </div>
          }
          description="We bring your brand to life, and give it character. This is the push you need to help your brand grow."
        />

        <div className="card-wrapper-implement">
          <SubServiceCardList serviceTitle={"Brand Implementation"} />
        </div>

        <div className="down-arrow-implement brand-imp-arrow">
          <Link exact to="/services-offered/web-app-dev">
            <h3>
              <IoIosArrowDown size={40} />
            </h3>
          </Link>
        </div>
      </section>
    );
  }
  if (service == "web-app-dev") {
    return (
      <section className="services-offered">
        {/* <DoubleHeaderDescription
        pageTitle={"Development"}
        spanTitle={"Services we offer"}
      /> */}
        <SocialHeaderDescription
          pageTitleDH={"Development"}
          spanTitleDH={"Services we offer"}
        />
        <MainService
          title={
            <div>
              Web & Application <span className="last-word">Development</span>
            </div>
          }
          description="These days, if something is not online, then it does not exist. We help you create great first and subsequent impressions with your presence on the web. Build tools to make life easier and more effective for you and your customers - gain that competitve advantage."
        />

        <div className="card-wrapper">
          <SubServiceCardList className="card" serviceTitle={"Development"} />
        </div>

        <div className="down-arrow webdev-down-arrow">
          <Link exact to="/services-offered/brand-planning">
            <h3>
              <IoIosArrowDown size={40} />
            </h3>
          </Link>
        </div>
      </section>
    );
  }
};
export default Services;
