import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import ServiceCards from "../Service-Card";
import DoubleHeaderDescription from "../DoubleHeaderDescription";
import SocialHeaderDescription from "../SocialHeaderDescription";
import "./servicesOverview.css";

const Services = (props) => {
  useEffect(() => {
    document.body.style.overflow = "hidden";
  }, []);
  const brandPlanning = ["Digital Strategy", "Graphic Design"];
  const brandImplementation = [
    "Social Media Management",
    "Online Marketing",
    "Copywriting",
  ];
  const WebApp = ["Web Development", "Mobile Apps"];
  return (
    <section className="outer-page">
      {/* <DoubleHeaderDescription
        pageTitle={"What we do"}
        spanTitle={"Services we offer"}
      /> */}
      <SocialHeaderDescription
        pageTitleDH={"What we do"}
        spanTitleDH={"Services we offer"}
      />
      <h2 className="ServiceTitle">
        Our Specialist <span>Services</span>
      </h2>
      <div className="ServiceBox">
        <ServiceCards
          title={
            <div>
              Brand Planning &<span className="hover-lastword"> Design</span>
            </div>
          }
          linkTag="brand-planning"
          description="Design and understand your brand."
          serviceTags={brandPlanning}
        />
        <ServiceCards
          title={
            <div>
              Brand <span className="hover-lastword"> Implementation</span>
            </div>
          }
          linkTag="brand-implementation"
          description="Bring your brand to life. This is the push you need to help your brand grow."
          serviceTags={brandImplementation}
        />
        <ServiceCards
          title={
            <div>
              Web & Application
              <span className="hover-lastword"> Development</span>
            </div>
          }
          linkTag="web-app-dev"
          description="Build tools to make life easier and more effective for you and your customers."
          serviceTags={WebApp}
        />
      </div>

      <div className="bottom-btn">
        <Link to="/portfolio">
          <button className="btn-white">See some of our work</button>
        </Link>
        <Link to="/project">
          <button className="btn-pink">Start your project </button>
        </Link>
      </div>
    </section>
  );
};

export default Services;
