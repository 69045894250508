/* eslint-disable react/react-in-jsx-scope */
import { FaPencilAlt } from "react-icons/fa";
import { GiChessKnight } from "react-icons/gi";
import { RiAdvertisementLine } from "react-icons/ri";
import { BiLike, BiMobileAlt } from "react-icons/bi";
import { IoIosCopy } from "react-icons/io";
import { MdOutlineDeveloperMode } from "react-icons/md";

export const serviceCardData = [
  {
    service: "Brand Planning",
    title: "Digital Strategy",
    icon: <GiChessKnight size={50} />,
    description:
      "Plan how digital technology can help improve your overall business (potentially uncovering cost savings and improved efficiency).",
  },
  {
    service: "Brand Planning",
    title: "Graphic Design",
    icon: <FaPencilAlt size={50} />,
    description: "Create the visual concepts to communciate your message.",
  },
  {
    service: "Brand Implementation",
    title: "Social Media Management",
    icon: <BiLike size={50} />,
    description:
      "Build or grow your social media presence or community around your brand, product, or service.",
  },
  {
    service: "Brand Implementation",
    title: "Online Marketing",
    icon: <RiAdvertisementLine size={50} />,
    description:
      "Reach the people looking for your product, service or content.",
  },
  {
    service: "Brand Implementation",
    title: "Copywriting",
    icon: <IoIosCopy size={50} />,
    description:
      "Say it with the right words, words that lead to action. We can help you write the text/content that you need.",
  },
  {
    service: "Development",
    title: "Web Development",
    icon: <MdOutlineDeveloperMode size={50} />,
    description:
      "Create a standard web site, or web application crafted specially for your online activities.",
  },
  {
    service: "Development",
    title: "Mobile Apps",
    icon: <BiMobileAlt size={50} />,
    description:
      "Mobile Apps are hot right now. So do not get left behind, let us build your gateway into a huge segment of potential customers. We can create applications for iOS and Android platforms.",
  },
];

export default serviceCardData;
