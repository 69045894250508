import React from "react";
import "./mainService.css";

const MainService = ({ title, description }) => {
  return (
    <section>
      <div className="top-section">
        <h2 className="services-title">{title}</h2>
        <p className="desc">{description}</p>
      </div>
    </section>
  );
};

export default MainService;
