import React from "react";

import "./styles.css";

const MenuFooter = (props) => (
  <footer id="footer">
    <div className="copyright">
      <ul className="alt">
        <li>
          <a href="/termsofuse">
            <span className="label terms">Terms of Use</span>
          </a>
        </li>

        <li>
          <a href="/privacypolicy">
            <span className="label terms">Privacy Policy</span>
          </a>
        </li>

        <li>
          <a href="/cookiepolicy">
            <span className="label terms">Cookie policy</span>
          </a>
        </li>
      </ul>
    </div>
  </footer>
);

const Footer = (props) => (
  <div className="footer-problem">
    <footer className="footer-container">
      <p className="footer-bottom-text">
        Spark Strand Ltd is a company registered in England and Wales with
        company number 10704227
      </p>
    </footer>
  </div>
);

export { MenuFooter };
export default Footer;
