import React, { useEffect } from "react";

// import "./styles.css";

const PrivacyPolicy = (props) => {
  useEffect(() => {
    document.body.style.overflow = "auto";
  }, []);

  return (
    <section className="wrapper style1">
      <header className="heading">
        <h2 className="title">Privacy notice</h2>
        <span className="subtitle">Your data and how we handle it</span>
      </header>
      <section className="inner">
        <p>
          This Privacy Notice (“Privacy Notice”) applies to the processing of
          personal data in connection with the provision of the Spark Strand
          website at{" "}
          <a href="http://www.sparkstrand.com">www.sparkstrand.com</a> (the
          “Site”), and all of the goods and services that we offer, including
          from the Site, Spark Strand offices and other such locations around
          the world (“Services”). We respect the privacy of every person who
          visits our Site and/or uses our Services and we are committed to
          ensuring a safe online experience for all.
        </p>

        <h2>1. Purpose of this privacy notice</h2>
        <p>
          This Privacy Notice explains our approach to any personal data that we
          might collect from you or which we have obtained about you from a
          third party and the purposes for which we process your personal data.
          This Privacy Notice also sets out your rights in respect of our
          processing of your personal data.
        </p>
        <p>
          When we talk about “personal data”, we mean any information which
          relates to an identified or identifiable living individual.
          Individuals might be identified by reference to a name, an
          identification number, location data, an online identifier (such as an
          IP address) or to other factors that are specific to them, such as
          their physical appearance.
        </p>
        <p>
          This Privacy Notice informs you of the nature of the personal data
          about you that is processed by us and how you can request that we
          delete it, update it, transfer it and/or provide you with access to
          it.
        </p>
        <p>
          This Privacy Notice is intended to assist you in making informed
          decisions when using the Site and our Services. Please take a moment
          to read and understand it. It should be read in conjunction with our
          Cookie Policy.
        </p>
        <p>
          This Privacy Notice only applies to the use of your personal data
          obtained by us, whether from you directly or from a third party. It
          does not apply to personal data collected by third parties during your
          communications with those third parties or your use of their products
          or services (for example, where you follow links to third party
          websites over which we have no control, or you purchase goods or
          services from those third parties).
        </p>
        <h2>2. About us</h2>
        <p>
          The Site and our Services are made available by Spark Strand Limited
          (“Spark Strand”, “we”, “us, “our”). Spark Strand is the data
          controller responsible for your personal data.{" "}
        </p>
        <p>
          <em>
            Spark Strand Limited (company no: 01945130) is an English company
            with its registered office at Bankside 3, 90-100 Southwark Street,
            London, SE1 0SW, England.
          </em>
        </p>
        <h2>3. How to contact us</h2>
        <p>
          If you have any questions about this Privacy Notice or want to
          exercise your rights set out in this Privacy Notice, you can contact
          us by:
        </p>
        <ul>
          <li>sending an email to legal@sparkstrand.com;</li>
          <li>
            writing to Bankside 3, 90-100 Southwark Street, London, SE1 0SW,
            England.
          </li>
        </ul>
        <h2>4. How we collect and receive personal data</h2>
        <p>We collect and receive personal data using different methods:</p>
        <ul>
          <li>Personal data you provide to us</li>
        </ul>
        <p>
          You may give us your personal data directly. This will be the case
          when, for example, you contact us with enquiries or complete forms on
          our Site, subscribe to receive our marketing communications or provide
          feedback to us.
        </p>
        <ul>
          <li>
            Personal data we collect using cookies and other similar
            technologies
          </li>
        </ul>
        <p>
          When you access and use our Site, we will collect certain technical
          information about your equipment, browsing actions and patterns. We
          collect this personal data by using cookies and other similar
          technologies (see the “
          <a href="#_9_OUR_USE">Our use of cookies and similar technologies</a>”
          section below).
        </p>
        <ul>
          <li>Personal data received from third parties</li>
        </ul>
        <p>
          From time to time, we will receive personal data about you from third
          parties. Such third parties may include analytics providers, payment
          providers, hotel and transport providers and third parties that
          provide technical services to us so that we can operate our Site and
          provide our Services.{" "}
        </p>
        <ul>
          <li>Publicly available personal data</li>
        </ul>
        <p>
          From time to time we may collect personal data about you (including
          your contact details, biography, interests or affiliations) from
          publicly available sources (including open source data sets), media
          reports or that you or a third party may otherwise make publicly
          available (for example through speeches at events or publishing
          articles or other news stories or posts on social media platforms).
        </p>
        <h2>5. Who we collect personal data about</h2>
        <p>We collect and process personal data from the following people:</p>
        <ul>
          <li>Site visitors</li>
        </ul>
        <p>
          If you browse our Site, register for updates on our Site, contact us
          with an enquiry through our Site, submit a complaint through our Site
          or use any Services available on our Site, we will collect and process
          your personal data in connection with your interaction with us and our
          Site.
        </p>
        <ul>
          <li>Customers</li>
        </ul>
        <p>
          If you buy products or services from us, we may collect and process
          your personal data in connection with the supply of goods or services
          to you.
        </p>
        <ul>
          <li>Event attendees</li>
        </ul>
        <p>
          If you attend one of our events, we will process personal data about
          you in connection with your attendance at the event. For example, we
          may ask you to complete a registration or feedback form, or other
          document relating to the event.
        </p>
        <ul>
          <li>
            Personnel that work for our partners and suppliers (including
            subcontractors)
          </li>
        </ul>
        <p>
          If you (or your organisation) supply products or services to us or
          otherwise partner with us, we may collect and process your personal
          data in connection with our receipt of those products and services
          and/or partnership. This may include personal data included in any
          email or telephone communications or recorded on any document relating
          to an order for the products or services.
        </p>
        <ul>
          <li>Job applicants</li>
        </ul>
        <p>
          If you apply for a job with us (whether in London or anywhere else we
          operate), whether through the Site or otherwise, we will collect and
          process your personal data in connection with your application.
        </p>
        <h2>6. Personal data we collect </h2>
        <p>We use your personal data for the following purposes:</p>
        <ul>
          <li>Fulfilment of our Services</li>
        </ul>
        <p>
          We collect and maintain personal data that you submit to us for the
          purpose of supplying Services that you have requested from us via our
          Site or where we contract with you to provide our Services. We may
          collect and process your personal data whether you are interacting
          with us on your own behalf or on behalf of any organisation you
          represent.{" "}
        </p>
        <p>
          The personal data we process may include your name and contact
          information (such as email address, postal address and telephone
          number) and your payment information (where applicable). We process
          this information so that we can fulfil the supply of Services,
          maintain our user databases and to keep a record of how our Services
          are being used.{" "}
        </p>
        <p>
          If you attend one of our events, we will process personal data about
          you which you volunteer in connection with your visit and any
          enquiries you may have. Some Services we offer are also subject to
          separate terms and conditions which will also apply.
        </p>
        <p>
          <em>
            Our legal basis for processing: It is necessary for us to use your
            personal data to perform our obligations in accordance with any
            contract that we may have with you, or it is in our legitimate
            interest or a third party’s legitimate interest to use personal data
            in such a way to ensure that we provide the our Services in an
            effective, safe and efficient way.
          </em>
        </p>
        <ul>
          <li>
            Linking to social media sites and interacting with our social media
            pages
          </li>
        </ul>
        <p>
          If you click on one of the social media links on our Site or otherwise
          interact with our social media pages such as on Facebook or Instagram
          (including interacting with any ‘like’ or similar embedded features on
          our Site or social media accounts) we and the relevant social media
          platform may receive information relating to such interaction and may
          share your personal data in connection with this purpose. For more
          information about how we use this personal data, please see the
          Insight and Analysis section below.
        </p>
        <p>
          Please note that we are a joint controller with the relevant social
          media platform in respect of the personal data we use which is
          collected via your use of our social media pages. Your personal data
          may be used by the relevant social media platform for additional
          purposes. For details of how the relevant social media platform uses
          your personal data, please see the privacy policy of the relevant
          social media platform.&nbsp;{" "}
        </p>
        <p>
          <em>
            Our legal basis for processing: It is in our legitimate interest or
            a third party’s legitimate interest to use personal data in such a
            way to ensure that we provide the Site and our Services in an
            effective way and to promote our Site and our Services via social
            media.
          </em>
        </p>
        <ul>
          <li>Customer service and general enquiries</li>
        </ul>
        <p>
          Our Site feature a “Get in touch” page which invites you to submit
          general enquiries about our Site and our Services by email, telephone
          or post.{" "}
        </p>
        <p>
          When you make an enquiry, we will collect and process your name,
          contact information (including email address and/or telephone number)
          and any other personal data that is relevant to your enquiry. We use
          this information to manage and respond to your enquiry.{" "}
        </p>
        <p>
          <em>
            Our legal basis for processing: It is in our legitimate interest to
            use your personal data in the ways described above to ensure that we
            are able to help you with your enquiry and provide a good standard
            of service to you.
          </em>
        </p>
        <ul>
          <li>Hosting and managing events</li>
        </ul>
        <p>
          From time to time, we may organise and host events. We may process
          your name and contact information (including email address, postal
          address and telephone number) to communicate with you about such
          events where you have specifically requested information about such
          events or where we have another lawful basis for sending that
          information to you.{" "}
        </p>
        <p>
          If you attend one of our events, we may use your personal data to
          record your attendance at the event and for related record-keeping
          purposes and, if relevant, we may collect and process any dietary
          requirements you may have. You may also feature in photographs taken
          at our events and such photographs may appear in publications that we
          make available.
        </p>
        <p>
          <em>
            Our legal basis for processing: It is necessary for us to use your
            personal data in this way to perform our obligations in accordance
            with any contract that we may have with you where you have signed up
            to attend an event, or it is in our legitimate interest or a third
            party’s legitimate interest to use personal data in such a way to
            ensure that the event is operated in an effective way.
          </em>
        </p>
        <p>
          <em>
            We may specifically ask your permission to use your photographs,
            quotes, testimonials, or other content that you make available or
            publish at the event. Where this is the case, our processing of your
            personal data will be based on consent.
          </em>
        </p>
        <ul>
          <li>Email and SMS/MMS marketing activities</li>
        </ul>
        <p>
          We use your name and email address to send you (or the organisation
          you represent) marketing communications by email. We may use your
          mobile telephone number to send you marketing communications by
          SMS/MMS. Our email and SMS/MMS marketing communications will include
          press releases, information, thought pieces, articles and details for
          events, as well as general information about our organisation, our
          Site, our US offices, the Services we provide and the events and
          promotions we offer.
        </p>
        <p>
          We may share your personal information with a variety of this party
          email or text message marketing providers (including MailChimp) who
          assist us in delivering our email marketing campaigns, newsletters,
          event invites and other mass emails or text messages to you.
        </p>
        <p>
          <em>
            Our legal basis for processing: Where data that we process is
            anonymised, we do not require a legal basis to use it as the
            information does not constitute personal data. However, our
            collection and use of such anonymised information may be subject to
            other laws where your consent is required. Please see our{" "}
            <a href="/">Cookie Policy</a> for further details.{" "}
          </em>
        </p>
        <p>
          <em>
            Where your personal data is not in an anonymous form (for example,
            your email address), it is in our legitimate interest to use your
            personal data for postal marketing purposes.{" "}
          </em>
        </p>
        <p>
          <em>
            We will only send you marketing communications by email where you
            have consented to receive such communications, or where we have
            another lawful right to send such communications to you.{" "}
          </em>
        </p>
        <ul>
          <li>Recruitment</li>
        </ul>
        <p>
          We use your personal data for recruitment purposes, in particular, to
          assess your suitability for any of our positions that you apply for,
          whether such application has been received by us online, by email or
          by hard copy and whether submitted directly by you, by a third party
          recruitment agency on your behalf or via the Spark Strand Workable
          recruitment portal (see the Workable privacy notice{" "}
          <a href="https://wolff-olins.workable.com/gdpr_policy">here</a>). We
          also use your personal data to communicate with you about the
          recruitment process, to keep records about our recruitment process and
          to comply with our legal and regulatory obligations in relation to
          recruitment.{" "}
        </p>
        <p>
          We will process any personal data about you that you volunteer,
          including during any interview, when you apply for a position with us.
          We may also process your personal data obtained from any third parties
          we work with in relation to our recruitment activities, including
          without limitation, recruitment agencies, background check providers,
          credit reference agencies and your referees.
        </p>
        <p>
          The personal data we process may include your name and contact
          information (including your email address, postal address and
          telephone number), details of your education, qualifications and
          employment history, any other personal data which appears in your
          curriculum vitae or application, any personal data that you volunteer
          during an interview or your interactions with us, or any personal data
          which is contained in any reference about you that we receive. Such
          information may also include special categories of personal data (such
          as information about your health, any medical conditions and your
          health and sickness records) and information relating to criminal
          convictions and offences if that information is relevant to the role
          you are applying for.
        </p>
        <p>
          We also use your personal data for the purposes of reviewing our equal
          opportunity profile in accordance with applicable legislation. We do
          not discriminate on the grounds of gender, race, ethnic origin, age,
          religion, sexual orientation, disability or any other basis covered by
          local legislation. All employment-related decisions are made entirely
          on merit.
        </p>
        <p>
          <em>
            Our legal basis for processing: Where we use your personal data in
            connection with recruitment, it will be in connection with us taking
            steps at your request to enter into a contract we may have with you
            or it is in our legitimate interest to use personal data in such a
            way to ensure that we can make the best recruitment decisions.{" "}
          </em>
        </p>
        <p>
          <em>
            We will not process any special (or sensitive) categories of
            personal data or personal data relating to criminal convictions or
            offences except where we are able to do so under applicable
            legislation or with your explicit consent.&nbsp;{" "}
          </em>
        </p>
        <ul>
          <li>Receipt of services from suppliers</li>
        </ul>
        <p>
          If we have engaged you or the organisation you represent to provide us
          with products or services (for example, if you or the organisation you
          represent provide us with services such as IT support, production,
          artwork or financial advice), we will collect and process your
          personal data in order to manage our relationship with you or the
          organisation you represent, to receive products and services from you
          or the organisation you represent and, where relevant, to provide our
          Services to others.{" "}
        </p>
        <p>
          The personal data we collect from you may include your name, job
          title, contact information (including email address, telephone number
          and postal address), bank account or other payment details and any
          other personal data you volunteer which is relevant to our
          relationship with you or the organisation you represent.
        </p>
        <p>
          <em>
            Our legal basis for processing: It is necessary for us to use your
            personal data to perform our obligations in accordance with any
            contract that we may have with you or it is in our legitimate
            interest to use personal data in such a way to ensure that we have
            an effective working relationship with you or the organisation you
            represent and are able to receive the services that you or your
            organisation provides, and provide our Services to others, in an
            effective way.
          </em>
        </p>
        <ul>
          <li>Security</li>
        </ul>
        <p>
          We have security measures in place at our offices, including CCTV and
          building access controls. There are signs in place showing that CCTV
          is in operation. The images captured are securely stored and only
          accessed on a need to know basis (e.g. to look into an incident). CCTV
          recordings are typically automatically overwritten after a short
          period of time unless an issue is identified that requires
          investigation (such as a theft).
        </p>
        <p>
          We may require visitors to our premises to sign in on arrival and
          where that is the case we will keep a record of visitors for a short
          period of time. Our visitor records are securely stored and only
          accessible on a need-to-know basis (e.g. to look into an incident).
        </p>
        <p>
          <em>
            Our legal basis for processing: It is in our legitimate interests to
            process your personal data so that we can keep our offices secure
            and provide a safe environment for our personnel and visitors to our
            offices.
          </em>
        </p>
        <ul>
          <li>Business administration and legal compliance</li>
        </ul>
        <p>
          We use your personal data for the following business administration
          and legal compliance purposes:
        </p>
        <p>- to comply with our legal obligations;</p>
        <p>- to enforce our legal rights; </p>
        <p>- to protect the rights of third parties; and</p>
        <p>
          - in connection with a business transition such as a merger,
          reorganisation, acquisition by another company, or sale of all or a
          portion of our assets.&nbsp;{" "}
        </p>
        <p>
          <em>
            Our legal basis for processing: Where we use your personal data in
            connection with a business transition, to enforce our legal rights
            or to protect the rights of third parties, it is in our legitimate
            interest to do so. For all other purposes described in this section,
            we have a legal obligation to use your personal data to comply with
            any legal obligations imposed upon us such as a court order.
          </em>
        </p>
        <p>
          <em>
            We will not process any special (or sensitive) categories of
            personal data or personal data relating to criminal convictions or
            offences except where we are able to do so under applicable
            legislation or with your explicit consent.
          </em>
        </p>
        <ul>
          <li>
            Any other purposes for which we wish to use your personal data that
            are not listed above, or any other changes we propose to make to the
            existing purposes, will be notified to you using the contact details
            we hold for you.
          </li>
        </ul>
        <h2>7. If you fail to provide your personal data </h2>
        <p>
          Where we are required by law to collect your personal data, or we need
          to collect your personal data under the terms of a contract we have
          with you, and you fail to provide that personal data when we request
          it, we may not be able to perform the contract we have or are trying
          to enter into with you. This may apply where you do not provide the
          personal data we need in order to provide the Services you have
          requested from us or to process an application for employment with us.
          In this case, we may have to cancel your application or the provision
          of the relevant Services to you, in which case we will notify you.
        </p>
        <h2>8. How we obtain your consent </h2>
        <p>
          Where our use of your personal data requires consent, you can provide
          or withdraw such consent:
        </p>
        <ul>
          <li>
            at the time we collect your personal data following the instructions
            provided; or
          </li>
          <li>
            by informing us using the contact details set out in the “How to
            contact us” section above.{" "}
          </li>
        </ul>
        <h2>9. Our use of cookies and similar technologies&nbsp; </h2>
        <p>
          Our Site may use certain cookies, web beacons, pixel tags, log files
          and other technologies. Please see our <a href="/">Cookie Policy</a>{" "}
          to find out more about the cookies and other similar technologies we
          use, the purposes for which we use them and how to manage, block or
          delete them.
        </p>
        <h2>10. Third party links and services </h2>
        <p>
          This Privacy Notice does not apply to your interaction with services
          provided by third parties.
        </p>
        <p>Our Site may contain links to third party websites and services.</p>
        <p>
          When you use a link to go from our Site to another website (even if
          you don’t leave our Site) or you request a service from a third party,
          this Privacy Notice shall not apply to the processing of your personal
          data carried out by the relevant third party provider.{" "}
        </p>
        <p>
          Your browsing and interactions on any other websites, or your dealings
          with any other third party service provider, is subject to that
          website’s or third party service provider’s own rules and policies.
          For example, our website invites you to view videos on platforms such
          as Vimeo. When you click on the links we provide to such platforms,
          you will be transferred from our website to the relevant platform and
          the privacy notice (and other terms and conditions) of that platform
          will apply to you.
        </p>
        <p>
          We do not monitor, control or endorse the privacy practices of any
          third parties.
        </p>
        <p>
          We encourage you to become familiar with the privacy practices of
          every website you visit or third party service provider that you use
          in connection with your interaction with us and to contact them if you
          have any questions about their respective privacy notices and
          practices.
        </p>
        <p>
          This Privacy Notice applies solely to personal data processed by us
          through your use of our Site, your receipt of our Services and/or in
          connection with our business operations. It does not apply to the
          processing of your personal data by these third party websites and
          third party service providers.{" "}
        </p>
        <h2>11. Sharing personal data </h2>
        <p>
          We will only share personal data with others when we are legally
          permitted to do so. When we share personal data with others, we put
          contractual arrangements and security mechanisms in place to protect
          the personal data shared and to comply with our data protection,
          confidentiality and security standards and obligations.&nbsp;
        </p>
        <p>
          When processing your personal data, we may need to share it with third
          parties (including other entities within our group of companies) as
          follows:
        </p>
        <ul>
          <li>
            Third-party organisations that provide applications/functionality,
            data processing or IT services: We share personal data with third
            parties who support us in providing our Services and help provide,
            run and manage our internal IT systems. Such third parties may
            include, for example, providers of information technology,
            cloud-based software as a service providers, identity management,
            website hosting and management, data analysis, data back-up,
            security and storage services. The servers powering and facilitating
            that cloud infrastructure are located in secure data centres around
            the world, and personal data may be stored in any one of them. We
            also share your personal data with third-party service providers to
            assist us with insight analytics. These providers are described in
            our <a href="/">Cookie Policy</a>.
          </li>
          <li>
            Payment providers and banks: We share personal data with third
            parties who assist us with the processing of payments and refunds.
          </li>
          <li>
            Event partners and suppliers: When we run events, we will share your
            personal data with third-party services providers that are assisting
            us with the operation and administration of that event. If we are
            running an event in partnership with other organisations, we will
            share your personal data with such organisations for use in relation
            to the event.{" "}
          </li>
          <li>
            Third-party email marketing and CRM specialists: We share personal
            data with specialist suppliers who assist us in managing our
            marketing database and sending out our email marketing
            communications and membership-related communications.
          </li>
          <li>
            Recruitment agencies and related organisations: We share personal
            data with external recruiters, third-party providers that undertake
            background checks on our behalf and other entities within our group
            of companies.
          </li>
          <li>
            Auditors, lawyers, accountants and other professional advisers: We
            share personal data with professional services firms who advise and
            assist us in relation to the lawful and effective management of our
            organisation and in relation to any disputes we may become involved
            in.
          </li>
          <li>
            Law enforcement or other government and regulatory agencies and
            bodies: We share personal data with law enforcement or other
            government and regulatory agencies or other third parties as
            required by, and in accordance with, applicable law or regulation.
          </li>
          <li>
            Sharing with other third parties: Occasionally, we may receive
            requests from third parties with authority to obtain disclosure of
            personal data, such as to check that we are complying with
            applicable law and regulation, to investigate an alleged crime, or
            to establish, exercise or defend legal rights. We will only fulfil
            requests for personal data where we are permitted to do so in
            accordance with applicable law or regulation.
          </li>
        </ul>
        <p>
          <em>
            This list is non-exhaustive and there may be circumstances where we
            need to share personal data with other third parties in order to
            operate our Site and to provide our Services.{" "}
          </em>
        </p>
        <h2>12. Transfers outside the European Economic Area (“eea”) </h2>
        <p>
          Where necessary in order to operate our Site and to otherwise deliver
          our Services, we will transfer personal data to countries outside the
          EEA.{" "}
        </p>
        <p>
          Non-EEA countries do not have the same data protection laws as the
          EEA. In particular, non-EEA countries may not provide the same degree
          of protection for your personal data, may not give you the same rights
          in relation to your personal data and may not have a data protection
          supervisory authority to help you if you have any concerns about the
          processing of your personal data. However, when transferring your
          personal data outside the EEA, we will comply with our legal and
          regulatory obligations in relation to your personal data, including
          having a lawful basis for transferring personal data and putting
          appropriate safeguards in place to ensure an adequate level of
          protection for the personal data.{" "}
        </p>
        <p>
          We will take reasonable steps to ensure the security of your personal
          data in accordance with applicable data protection laws.
        </p>
        <p>
          When transferring your personal data outside the EEA, we will ensure
          that, where required by applicable law, at least one of the following
          safeguards is implemented:
        </p>
        <ul>
          <li>
            Adequacy decisions: We will only transfer your personal data to
            countries that have been deemed to provide an adequate level of
            protection for personal data by the European Commission. For further
            details, see{" "}
            <a href="https://ec.europa.eu/info/law/law-topic/data-protection/data-transfers-outside-eu/adequacy-protection-personal-data-non-eu-countries_en">
              https://ec.europa.eu/info/law/law-topic/data-protection/data-transfers-outside-eu/adequacy-protection-personal-data-non-eu-countries_en
            </a>
            .&nbsp;{" "}
          </li>
          <li>
            Model clauses: Where we use certain service providers, we may use
            specific contracts approved by the European Commission which give
            personal data the same protection it has in Europe. For further
            details, see{" "}
            <a href="https://ec.europa.eu/info/law/law-topic/data-protection/data-transfers-outside-eu/model-contracts-transfer-personal-data-third-countries_en">
              https://ec.europa.eu/info/law/law-topic/data-protection/data-transfers-outside-eu/model-contracts-transfer-personal-data-third-countries_en
            </a>
            .
          </li>
          <li>
            EU-U.S. Privacy Shield: Where we have partners or suppliers based in
            the US, we may transfer data to them if they are part of the Privacy
            Shield which requires them to provide similar protection to personal
            data shared between Europe and the US. For further details, see{" "}
            <a href="https://ec.europa.eu/info/law/law-topic/data-protection/data-transfers-outside-eu/eu-us-privacy-shield_en">
              https://ec.europa.eu/info/law/law-topic/data-protection/data-transfers-outside-eu/eu-us-privacy-shield_en
            </a>
            .
          </li>
        </ul>
        <p>
          <em>
            Please contact us if you would like further information on the
            specific mechanisms used by us when transferring your personal data
            outside the EEA.
          </em>
        </p>
        <h2>13. How long we keep your personal data </h2>
        <p>
          In respect of personal data that we process in connection with the
          supply of our Services, we may retain your personal data for up to six
          years from the date of supply of the relevant Services and in
          compliance with our data protection obligations. We may then destroy
          such files without further notice or liability.
        </p>
        <p>
          In respect of any other personal data that we process, we will retain
          relevant personal data for up to three years from the date of our last
          interaction with you and in compliance with our data protect
          obligations. We may then destroy such files without further notice or
          liability.{" "}
        </p>
        <p>
          If any personal data is only useful for a short period (e.g. for a
          specific event or marketing campaign or in relation to recruitment),
          we will not retain it for longer than the period for which it is used
          by us.
        </p>
        <p>
          If you have opted out of receiving marketing communications from us,
          we will need to retain certain personal data on a suppression list
          indefinitely so that we know not to send you further marketing
          communications in the future.
        </p>
        <h2>14. Confidentiality and security of your personal data </h2>
        <p>
          We are committed to keeping the personal data you provide to us secure
          and we will take reasonable precautions to protect your personal data
          from loss, misuse or alteration.{" "}
        </p>
        <p>
          We have implemented information security policies, rules and technical
          measures to protect the personal data that we have under our control
          from:
        </p>
        <ul>
          <li>unauthorised access;</li>
          <li>improper use or disclosure;</li>
          <li>unauthorised modification; and</li>
          <li>unlawful destruction or accidental loss.</li>
        </ul>
        <p>
          All our employees and data processors (i.e. those who process your
          personal data on our behalf, for the purposes listed above) who have
          access to and are associated with the processing of personal data are
          obliged to respect the confidentiality of the personal data of all
          users of our Site and our Services.
        </p>
        <h2>15. Personal data of children </h2>
        <p>
          We do not specifically target our Site or our Services at children.
          However, due to the nature of our organisation and the Services we
          provide, we may from time to time collect and process personal data
          relating to individuals under the age of 18. Where we do so, we will
          comply with all applicable laws and regulations relating to the
          processing of personal data of minors.{" "}
        </p>
        <h2>16. How to access your information and your other rights</h2>
        <p>
          You have the following rights in relation to the personal data we hold
          about you. If you would like to exercise any of these rights, please
          contact us using the details set out in on our{" "}
          <a href="/contact">Contact</a> page<em>.</em>
        </p>
        <ul>
          <li>Your right of access</li>
        </ul>
        <p>
          If you ask us, we will confirm whether we are processing your personal
          data and, if so, provide you with a copy of that personal data (along
          with certain other details). If you require additional copies, we may
          charge a reasonable fee for producing those additional copies.
        </p>
        <ul>
          <li>Your right to rectification</li>
        </ul>
        <p>
          If the personal data we hold about you is inaccurate or incomplete,
          you are entitled to have it rectified. If we have shared your personal
          data with others, we’ll let them know about the rectification where
          possible. If you ask us, where possible and lawful to do so, we will
          also tell you who we’ve shared your personal data with so that you can
          contact them directly.{" "}
        </p>
        <ul>
          <li>Your right to erasure</li>
        </ul>
        <p>
          You can ask us to delete or remove your personal data in some
          circumstances, such as where we no longer need it or where you
          withdraw your consent (where applicable). If we have shared your
          personal data with others, we will let them know about the erasure
          where possible. If you ask us, where it is possible and lawful for us
          to do so, we will also tell you who we have shared your personal data
          with so that you can contact them directly.
        </p>
        <ul>
          <li>Your right to restrict processing</li>
        </ul>
        <p>
          You can ask us to ‘block’ or suppress the processing of your personal
          data in certain circumstances such as where you contest the accuracy
          of that personal data or you object to us processing it for a
          particular purpose. This may not mean that we will stop storing your
          personal data but, where we do keep it, we will tell you if we remove
          any restriction that we have placed on your personal data to stop us
          processing it further. If we’ve shared your personal data with others,
          we’ll let them know about the restriction where it is possible for us
          to do so. If you ask us, where it is possible and lawful for us to do
          so, we’ll also tell you who we’ve shared your personal data with so
          that you can contact them directly.{" "}
        </p>
        <ul>
          <li>Your right to data portability</li>
        </ul>
        <p>
          You have the right, in certain circumstances, to obtain personal data
          you have provided to us (in a structured, commonly used and machine
          readable format) and to reuse it elsewhere or to ask us to transfer it
          to a third party of your choice.
        </p>
        <ul>
          <li>Your right to object</li>
        </ul>
        <p>
          You can ask us to stop processing your personal data, and we will do
          so, if we are:
        </p>
        <p>
          - relying on our own or someone else’s legitimate interest to process
          your personal data, except if we can demonstrate compelling legal
          grounds for the processing; or
        </p>
        <p>
          - processing your personal data for the purposes direct marketing.
        </p>
        <ul>
          <li>
            Your rights in relation to automated decision-making and profiling
          </li>
        </ul>
        <p>
          You have the right not to be subject to a decision when it is based on
          automatic processing, including profiling, if it produces a legal
          effect or similarly significantly affects you, unless such profiling
          is necessary for the entering into, or the performance of, a contract
          between you and us.
        </p>
        <ul>
          <li>Your right to withdraw consent</li>
        </ul>
        <p>
          If we rely on your consent (or explicit consent) as our legal basis
          for processing your personal data, you have the right to withdraw that
          consent at any time. You can exercise your right of withdrawal by
          contacting us using our contact details in the “Get in touch” section
          above or by using any other opt-out mechanism we may provide, such as
          an unsubscribe link in an email.&nbsp;&nbsp;{" "}
        </p>
        <ul>
          <li>
            Your right to lodge a complaint with the supervisory authority
          </li>
        </ul>
        <p>
          If you have a concern about any aspect of our privacy practices,
          including the way we have handled your personal data, please contact
          us using the contact details provided in the “How to contact us”
          section above. You can also report any issues or concerns to a
          national supervisory authority in the Member State of your residence
          or the place of the alleged infringement. You can find a list of
          contact details for all EU supervisory authorities at:{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm"
          >
            http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm
          </a>
          . As we are incorporated in the UK, our regulatory authority is{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://ico.org.uk"
          >
            https://ico.org.uk
          </a>
          .
        </p>
        <h2>17. Changes to this privacy notice</h2>
        <p>We may make changes to this Privacy Notice from time to time</p>
        <p>
          To ensure that you are always aware of how we use your personal data,
          we&nbsp;will update this Privacy Notice from time to time to reflect
          any changes or proposed changes to our use of your personal data. We
          may also make changes to comply with changes in applicable law or
          regulatory requirements.{" "}
        </p>
        <p>
          We will notify you by e-mail of any significant changes to this
          Privacy Notice. However, we encourage you to review this Privacy
          Notice periodically to be informed of how we use your personal data.
        </p>

        <p>Last updated 05 March 2019</p>
      </section>
    </section>
  );
};

export default PrivacyPolicy;
