import React from "react";
import "./styles.css";

const SocialHeaderDescription = ({ pageTitleDH, spanTitleDH }) => {
  return (
    <div className="description-containerDH">
      <h2 className="titleDH">{pageTitleDH}</h2>
      <span className="subtitleDH">{spanTitleDH}</span>
    </div>
  );
};

export default SocialHeaderDescription;
