import React from "react";
import "./subServiceCard.css";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

const SubServiceCard = ({ icon, title, description }) => {
  return (
    <section>
      <div className="card-holder">
        <div className="tile">
          <h3 className="service-title">
            <Link to="/">
              <div className="service-icon">{icon}</div>
            </Link>

            <Link to="/">
              <div>{title}</div>
            </Link>
          </h3>

          <p className="service-desc">{description}</p>
        </div>
      </div>
    </section>
  );
};

export default SubServiceCard;
