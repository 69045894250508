import React, { useEffect, useState } from "react";
import { BsArrowsExpand, BsArrowsCollapse } from "react-icons/bs";
import ExpandingFAQ from "../ExpandingFAQ";
import "./styles.css";

const FAQ = () => {
  useEffect(() => {
    document.body.style.overflow = "auto";
  }, []);
  const [swapState, setSwapState] = useState(false);
  const swapStateExpand = () => {
    setSwapState((prevSwapState) => !prevSwapState);
  };
  return (
    <div className="faq-container">
      <h2 className="faq-heading">FAQs</h2>
      <div className="faq-question-container">
        {!swapState ? (
          <div className="expand-all-btn" onClick={swapStateExpand}>
            {" "}
            <BsArrowsExpand className="expand-all-btn-icon" /> Expand All
          </div>
        ) : (
          <div className="expand-all-btn" onClick={swapStateExpand}>
            {" "}
            <BsArrowsCollapse className="expand-all-btn-icon" />
            Collapse All
          </div>
        )}
        <ExpandingFAQ
          question={"Lorem ipsum dolor sit amet consectetur adipisicing elit?"}
          answer={
            "Dolores sit, quod accusamus perferendis eligendi qui commodi, soluta ratione obcaecati amet iste enim aut. Maxime cum veritatis, soluta magni rerum eum!"
          }
          swapState={swapState}
          setSwapState={setSwapState}
        />
        <ExpandingFAQ
          question={"Lorem ipsum dolor sit amet consectetur adipisicing elit?"}
          answer={
            "Dolores sit, quod accusamus perferendis eligendi qui commodi, soluta ratione obcaecati amet iste enim aut. Maxime cum veritatis, soluta magni rerum eum! Dolores sit, quod accusamus perferendis eligendi qui commodi, soluta ratione obcaecati amet iste enim aut. Maxime cum veritatis, soluta magni rerum eum!"
          }
          swapState={swapState}
          setSwapState={setSwapState}
        />
        <ExpandingFAQ
          question={"Lorem ipsum dolor sit amet consectetur adipisicing elit?"}
          answer={
            "Dolores sit, quod accusamus perferendis eligendi qui commodi, soluta ratione obcaecati amet iste enim aut. Maxime cum veritatis, soluta magni rerum eum!"
          }
          swapState={swapState}
          setSwapState={setSwapState}
        />
        <ExpandingFAQ
          question={"Lorem ipsum dolor sit amet consectetur adipisicing elit?"}
          answer={
            "Dolores sit, quod accusamus perferendis eligendi qui commodi, soluta ratione obcaecati amet iste enim aut. Maxime cum veritatis, soluta magni rerum eum!"
          }
          swapState={swapState}
          setSwapState={setSwapState}
        />
      </div>
    </div>
  );
};

export default FAQ;
