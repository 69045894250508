import React, { useState } from "react";
import "./styles.css";

const ExpandingFAQ = ({ question, answer, swapState, setSwapState }) => {
  const [openQuestion, setOpenQuestion] = useState(false);
  const switchOpenQ = () => {
    setOpenQuestion((prevOpenQuestion) => !prevOpenQuestion);
  };

  return (
    <div className="faq-single-container">
      {!openQuestion && !swapState ? (
        <div>
          {" "}
          <div className="faq-q-plus">
            <div className="faq-btn" onClick={switchOpenQ}>
              +
            </div>
            <h3 className="faq-q-a">Q.</h3>
            <p className="question-unset">{question}</p>
          </div>
        </div>
      ) : (
        <div>
          {" "}
          <div className="faq-q-plus">
            <div className="faq-btn" onClick={switchOpenQ}>
              -
            </div>
            <h3 className="faq-q-a">Q.</h3>
            <p className="faq-question">{question}</p>
          </div>
          <div className="faq-q-plus">
            <p className="faq-question answer">{answer}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default ExpandingFAQ;
