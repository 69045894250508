import React from "react";
import { Link } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa";
import PortfolioCard from "../Portfolio-card";
import "./styles.css";

const PortfolioCardList = ({ portfolioData }) => {
  return (
    <>
      {portfolioData.map((portfolioItem, index) => (
        <PortfolioCard key={index} data={portfolioItem} />
      ))}
      <div className="container">
        <div className="card question">
          <div className="face face1">
            <div className="content">
              <p className="description">
                This spot could be yours. Click here to start your project.
              </p>
              <div className="tags"></div>
              <Link exact to="project">
                <FaArrowRight className="arrow" />
              </Link>
            </div>
          </div>
          <div className="face face2 question">
            <img
              src={
                "https://upload.wikimedia.org/wikipedia/commons/thumb/5/55/Question_Mark.svg/2000px-Question_Mark.svg.png"
              }
              alt={"Question mark"}
              className="cardImage"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default PortfolioCardList;
