import React, { useState, useEffect } from "react";
import "./styles.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";
import { GiHamburgerMenu as BurgerMenu } from "react-icons/gi";
import { RiCloseLine as CloseMenu } from "react-icons/ri";
import { AiOutlineInfo as InfoIcon } from "react-icons/ai";

const ActionButton = ({
  firstAction,
  firstLink,
  secondAction,
  secondLink,
  thirdLink,
  thirdAction,
}) => {
  AOS.init();
  const [openActionMenu, setOpenActionMenu] = useState(false);
  const [isMobile, setIsMobile] = useState("fade-up");
  const actionSwitchState = () => {
    setOpenActionMenu((prevOpenActionMenu) => !prevOpenActionMenu);
  };
  useEffect(() => {
    window.addEventListener(
      "resize",
      () => {
        if (window.innerWidth > 400) {
          setIsMobile("fade-up");
        } else {
          setIsMobile("fade-right");
        }
      },
      false
    );
  }, [isMobile]);
  return (
    <>
      <div className="outeractioncircle" onClick={actionSwitchState}>
        <InfoIcon />
      </div>
      {openActionMenu && (
        <>
          <div
            className="outeractioncircle menu-close"
            onClick={actionSwitchState}
          >
            <CloseMenu className="menu-icon-close" />
          </div>
          <div
            className="outeractionbutton-container"
            data-aos={isMobile}
            data-aos-delay="50"
            data-aos-duration="100"
          >
            <Link exact to={firstLink}>
              <div className="outeractioncircle-one">{firstAction}</div>
            </Link>
          </div>
          <div
            className="outeractionbutton-container-two"
            data-aos={isMobile}
            data-aos-delay="80"
            data-aos-duration="100"
          >
            <Link exact to={secondLink}>
              <div className="outeractioncircle-two">{secondAction}</div>
            </Link>
          </div>
          <div
            className="outeractionbutton-container-three"
            data-aos={isMobile}
            data-aos-delay="100"
            data-aos-duration="100"
          >
            <Link exact to={thirdLink}>
              <div className="outeractioncircle-three">{thirdAction}</div>
            </Link>
          </div>
        </>
      )}
    </>
  );
};

export default ActionButton;
