import React from "react";
import "./styles.css";
import Image from "../../images/rainbow-what-we-do.jpeg";

const ClientCaseStudy = ({ companyName, description, closeModal }) => {
  const modal = document.getElementsByClassName("modal");

  const closeBox = () => {
    modal.style.display = "none";
  };

  return (
    <div className="caseStudy">
      <div className="caseStudy-image-container">
        <img src={Image} alt={companyName} className="caseStudy-image" />
      </div>
      <div className="caseStudy-content">
        <button className="caseStudy-close" onClick={closeModal}>
          CLOSE
        </button>
        <h1 className="caseStudy-name">{companyName}</h1>
        <p className="caseStudy-description">{description}</p>
      </div>
    </div>
  );
};

export default ClientCaseStudy;
