import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { FaUserAlt as UserIcon } from "react-icons/fa";
import Logo from "../Logo";
import SlideOutMenuDH from "../SlideOutMenuDH";

import "./styles.css";

const DoubleHeader = (props) => {
  const [openSideMenuOptions, setSideMenuOptions] = useState(false);
  const switchStateSideMenu = () => {
    setSideMenuOptions((prevOpenSideMenuOptions) => !prevOpenSideMenuOptions);
  };
  const openSideMenu = () => {
    setSideMenuOptions(true);
  };

  const closeSideMenu = () => {
    setSideMenuOptions(false);
  };
  return (
    <React.Fragment>
      <header className="secondHeader">
        <div className="secondHeader-left">
          <Logo slider={false} location={useLocation().pathname} />
        </div>
        <div className="secondHeader-right">
          <Link to="/services">
            <p className="secondHeader-tags tags">Our Services</p>
          </Link>
          <Link to="/portfolio">
            <p className="secondHeader-tags tags">Our Portfolio</p>
          </Link>
          <Link to="/pencraft">
            <p className="secondHeader-tags tags">Our Thoughts</p>
          </Link>
          <Link to="/contact">
            <p className="secondHeader-tags tags">Contact</p>
          </Link>
          <Link to="/clients">
            <p className="tags">
              <UserIcon className="secondHeader-user tags" /> Client Area{" "}
            </p>
          </Link>
        </div>
        <div className="right-slideout-menu-mobile">
          <div onClick={switchStateSideMenu} className="menu-hover">
            <i className="fa fa-bars"></i> Menu
          </div>
          {openSideMenuOptions && (
            <SlideOutMenuDH
              openSideMenu={openSideMenu}
              closeSideMenu={closeSideMenu}
            />
          )}
        </div>
      </header>
    </React.Fragment>
  );
};

export default DoubleHeader;
