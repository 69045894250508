import React, { useEffect, useRef, useState } from "react";
import "./styles.css";
import { Link, useLocation } from "react-router-dom";
import {
  AiFillSkype as Skype,
  AiFillFacebook as Facebook,
  AiFillInstagram as Instagram,
  AiOutlineTwitter as Twitter,
  AiFillLinkedin as Linkedin,
  AiOutlineWhatsApp as WhatsApp,
  AiOutlineForm as ComposeEmail,
} from "react-icons/ai";
import { IoMdListBox as FormIcon } from "react-icons/io";
import { FaEnvelope as Envelope, FaPhoneAlt as Phone } from "react-icons/fa";
import MessageForm from "../MessageForm";
import EmailOptionsModal from "../EmailOptionsModal";
import Webtalk from "../Webtalk";

const TopHeader = (props) => {
  const ref = useRef();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isPhoneMenuOpen, setIsPhoneMenuOpen] = useState(false);
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (isMenuOpen && ref.current && !ref.current.contains(e.target)) {
        setIsMenuOpen(false);
        setIsPhoneMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isMenuOpen]);
  // const [openEmailOptions, setOpenEmailOptions] = useState(false);
  const { openModal } = props;

  // const openEmailModal = () => {
  //   setOpenEmailOptions(true);
  // };

  // const closeEmailModal = () => {
  //   setOpenEmailOptions(false);
  // };
  const setTwoStates = () => {
    setIsPhoneMenuOpen(!isPhoneMenuOpen);
    setIsMenuOpen(!isMenuOpen);
  };
  const setTwoStatesFalse = () => {
    setIsPhoneMenuOpen(false);
    setIsMenuOpen(false);
  };
  const ChatLink = () =>
    props.online && (
      <Link
        onClick={() => props.chatBox("Chat with us", <MessageForm />)}
        to="/chat"
        className="online"
      >
        <i className="fa fa-comment-alt"></i>
        Chat with us - we're online
      </Link>
    );

  const openWebCall = (event) => {
    // props.openModal("Talk to us", <Webtalk />);
    // event.preventDefault();
  };

  const EmailLink = () => (
    <div
      className="EmailLink-container topHeader-links"
      onClick={() => setIsMenuOpen(true)}
    >
      <Envelope className="EmailLink" />
      <div className="phone-tag-writing">hello@sparkstrand.com</div>
    </div>
  );

  return (
    <div className="topHeader">
      <div className="topHeader-left">
        <a
          className="topHeader-links"
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.facebook.com/sparkstrand/"
        >
          <Facebook className="fb-social" />
        </a>
        <a
          className="topHeader-links"
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.instagram.com/spark_strand/"
        >
          <Instagram className="insta-social" />
        </a>
        <a
          className="topHeader-links"
          target="_blank"
          rel="noopener noreferrer"
          href="https://twitter.com/sparkstrand"
        >
          <Twitter className="twt-social" />
        </a>
        <a
          className="topHeader-links"
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.linkedin.com/company/sparkstrand"
        >
          <Linkedin className="linkedin-social" />
        </a>
        <a
          className="topHeader-links"
          target="_blank"
          rel="noopener noreferrer"
          href="https://api.whatsapp.com/send?phone=447985538094"
        >
          <WhatsApp className="whtsapp-social" />
        </a>
      </div>
      <div className="topHeader-right">
        {/* <li>
          <ChatLink online={false} {...props} />
        </li> */}

        <EmailLink {...props} />
        {/* {openEmailOptions && (
          <EmailOptionsModal
            closeEmailModal={closeEmailModal}
            openModal={openModal}
          />
        )} */}

        {isMenuOpen && !isPhoneMenuOpen && (
          <ul className="topHeader-list" ref={ref}>
            <li className="topHeader-list-item-two">
              <Link to="/contact/">
                <button
                  className="topHeader-btn-two"
                  onClick={() => setIsMenuOpen(false)}
                >
                  <FormIcon className="topHeader-icon-two" /> Use our Contact
                  Form
                </button>
              </Link>
            </li>
            <li className="topHeader-list-item-one">
              <a href="mailto:hello@sparkstrand.com?subject=Enquiry for Service">
                <button
                  className="topHeader-btn-one"
                  onClick={() => setIsMenuOpen(false)}
                >
                  <ComposeEmail className="topHeader-icon-one" /> Use your Email
                  Client
                </button>
              </a>
            </li>
          </ul>
        )}

        <a className="phone-container topHeader-links" onClick={setTwoStates}>
          <Phone className="EmailLink" />
          <div className="phone-tag-writing">(+44) 0330 133 4777</div>
        </a>

        {isMenuOpen && isPhoneMenuOpen && (
          <ul className="topHeader-list-forPhoneDropDown" ref={ref}>
            <li className="topHeader-list-item-one">
              <a href="mailto:hello@sparkstrand.com?subject=Enquiry for Service">
                <button
                  className="topHeader-btn-one"
                  onClick={setTwoStatesFalse}
                >
                  <Skype
                    size={25}
                    className="topHeader-icon-one-forPhoneDropDown"
                  />
                  <div className="skype-text">Call us on Skype</div>
                </button>
              </a>
            </li>
            <li className="topHeader-list-item-two">
              <Link to="/contact/">
                <button
                  className="topHeader-btn-two"
                  onClick={setTwoStatesFalse}
                >
                  <div className="webtalk-text">Call us on WebTalk</div>
                </button>
              </Link>
            </li>
          </ul>
        )}
      </div>
    </div>
  );
};

export default TopHeader;
