/* eslint-disable import/no-named-as-default */
/* eslint-disable react/jsx-key */
import React from "react";
import SubServiceCard from "../components/SubServiceCard";
import serviceCardData from "../servicesData";

const SubServiceCardList = (props) => {
  const { serviceTitle } = props;
  const filteredServices = serviceCardData.filter((serviceInfo) => {
    return serviceInfo.service.includes(serviceTitle);
  });
  const serviceShow = filteredServices.map((serviceInfo, index) => {
    return (
      <SubServiceCard
        key={index}
        service={serviceInfo.service}
        title={serviceInfo.title}
        icon={serviceInfo.icon}
        description={serviceInfo.description}
      />
    );
  });
  return <>{serviceShow}</>;
};

export default SubServiceCardList;
