/* eslint-disable no-shadow */

import React from "react";
import ReactSlider from "react-slider";

import "./styles.css";

// invert={true} on first load
// {state.valueNow}

const logoClick = (position = 0) => {
  if (!position) document.location.href = "/";
};

const LogoImage = (props) => (
  <div className="logo" onClick={props.onClick}>
    <img src="images/emblem.png" alt="Spark Strand" />
  </div>
);

const LogoSlider = (props) => {
  const [value, setValue] = React.useState(100);

  if (value > 0) {
    setTimeout(() => setValue(value - 1), 50);
  }

  return (
    <div className="logoSlider">
      <ReactSlider
        value={value}
        className="horizontal-slider"
        thumbClassName="knob"
        trackClassName="track"
        onAfterChange={(val) => setValue(val)}
        renderThumb={(props, state) => (
          <div {...props}>
            <LogoImage onClick={() => logoClick(value)} />
          </div>
        )}
      />
      <span className="sliderEnd"></span>
    </div>
  );
};

const Logo = (props) =>
  props.slider && props.location === "/" ? (
    <LogoSlider />
  ) : (
    <LogoImage onClick={() => logoClick()} />
  );

export default Logo;
