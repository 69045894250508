import React, { useState } from "react";
import "./styles.css";
import { Link, useParams } from "react-router-dom";
import { HiCursorClick as ClickIcon } from "react-icons/hi";

const ServiceCards = ({ title, description, linkTag, serviceTags }) => {
  return (
    <Link exact to={`services-offered/${linkTag}`}>
      <div className="card">
        <div className="heading-container">
          <div className="info hover-button">
            <span className="hover-button--off">
              <ClickIcon className="card-clickIcon" />
            </span>
            <span className="hover-button--on">
              <ClickIcon className="card-clickIcon" />
            </span>
          </div>
          <h2 className="main-title">{title}</h2>
        </div>
        <div className="btn-container">
          {serviceTags.map((serviceTag, index) => (
            <button key={index} className="btn-pink-more">
              {serviceTag}
            </button>
          ))}
        </div>
        <div className="desc-container">
          <p>{description}</p>
        </div>
      </div>
    </Link>
  );
};

export default ServiceCards;
