export default [
  {
    name: "Web Development",
    population: 4780127,
    capital: "Montgomery",
    region: "South",
  },
  {
    name: "Graphic Design",
    population: 710249,
    capital: "Juneau",
    region: "West",
  },
  {
    name: "Digital Strategy",
    population: 6392307,
    capital: "Phoenix",
    region: "West",
  },
  {
    name: "Social Media Management",
    population: 2915958,
    capital: "Little Rock",
    region: "South",
  },
  {
    name: "Online Marketing",
    population: 37254503,
    capital: "Sacramento",
    region: "West",
  },
  {
    name: "Copywriting",
    population: 5029324,
    capital: "Denver",
    region: "West",
  },
  {
    name: "Mobile Apps",
    population: 3574118,
    capital: "Hartford",
    region: "Northeast",
  },
  {
    name: "Other",
    population: 897936,
    capital: "Dover",
    region: "South",
  },
];
